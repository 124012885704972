<template>
  <div>

    <app-navigation :title="$t('accessory.accessory-add')" :backButtonText="$t('navigation.back')"></app-navigation>

    <el-steps :active="activeStep" finish-status="success" style="margin: 20px">
      <el-step :title="$t('accessory.step-one')" :description="$t('accessory.step-one-desc')"></el-step>
      <el-step :title="$t('accessory.step-two')" :description="$t('accessory.step-two-desc')"></el-step>
      <el-step :title="$t('accessory.step-three')" :description="$t('accessory.step-three-desc')"></el-step>
    </el-steps>

    <el-row style="margin-left: 20px; margin-right: 20px">
      <el-col :span="12">
        <el-button  type="text" @click.native.prevent="prevStep" icon="el-icon-d-arrow-left">{{ $t('accessory.back') }}</el-button>
      </el-col>

      <el-col :span="12">
        <el-button style="float: right" type="text" @click.native.prevent="nextStep" :loading="loading">{{ activeStep == 2 ? $t('accessory.save') : $t('accessory.next')}}
          <i :class="{'el-icon-d-arrow-right': activeStep < 2, 'el-icon-download': activeStep == 2}"></i></el-button>
      </el-col>

    </el-row>

    <el-form :model="accessoryData" ref="accessoryForm" class="form-container" label-position="left" label-width="220px" status-icon>
      <component ref="comp" :is="steps[activeStep]" :accessoryData="accessoryData"></component>
    </el-form>


  </div>
</template>

<script>
import {categoryNames, className, group } from "@/utils/accessory-utils";
import {
  driverTitle,
  DRIVER_TYPE_MODBUS,
  DRIVER_TYPE_LOGO,
  DRIVER_TYPE_KNXTP,
  DRIVER_TYPE_KNXIP,
  DRIVER_TYPE_GPIO,
  DRIVER_TYPE_COOLMASTER,
} from "@/utils/driver-utils";
import Navigation from "@/components/Navigation";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import LogoSwitchInputs from "./components/logo/LogoSwitchInputs";
import LogoSensorInputs from "./components/logo/LogoSensorInputs";
import LogoShutterInputs from "./components/logo/LogoShutterInputs";
import KnxSwitchInputs from "./components/knx/KnxSwitchInputs";
import KnxDimmerInputs from "./components/knx/KnxDimmerInputs";
import KnxTunableWhiteInputs from "./components/knx/KnxTunableWhiteInputs.vue";
import KnxShutterInputs from "./components/knx/KnxShutterInputs";
import KnxThermostatInputs from "./components/knx/KnxThermostatInputs";
import KnxPunchInputs from "./components/knx/KnxPunchInputs";
import KnxBinaryInputs from "./components/knx/KnxBinaryInputs";
import GpioSensorInputs from "./components/gpio/GpioSensorInputs";
import GpioSwitchInputs from "./components/gpio/GpioSwitchInputs";
import KnxAdvThermostatInputs from "./components/knx/KnxAdvThermostatInputs";
import KnxSensorInputs from "./components/knx/KnxSensorInputs";
import KnxStatusDisplayInputs from "./components/knx/KnxStatusDisplayInputs";
import KnxRGBWDimmerInputs from "./components/knx/KnxRGBWDimmerInputs";
import KnxAudioInputs from "./components/knx/KnxAudioInputs";
import ModbusSwitchInputs from "./components/modbus/ModbusSwitchInputs";
import ModbusSensorInputs from "./components/modbus/ModbusSensorInputs";
import ModbusAdvancedThermostatInputs from "./components/modbus/ModbusAdvancedThermostatInputs";
import CoolmasterThermostatInputs from "./components/coolmaster/CoolmasterThermostatInputs";

import {
  DEFAULT_ROOM_ID,
  ACCESSORY_CATEGORY_CONTACTOR,
  ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
  ACCESSORY_CATEGORY_ELEVATOR,
  ACCESSORY_CATEGORY_GAS_VALVE,
  ACCESSORY_CATEGORY_SENSOR_DOOR,
  ACCESSORY_CATEGORY_SENSOR_GAS,
  ACCESSORY_CATEGORY_SENSOR_LEAK,
  ACCESSORY_CATEGORY_SENSOR_MOTION,
  ACCESSORY_CATEGORY_SENSOR_SMOKE,
  ACCESSORY_CATEGORY_SENSOR_WINDOW,
  ACCESSORY_CATEGORY_SIREN,
  ACCESSORY_CATEGORY_SWITCH,
  ACCESSORY_CATEGORY_WATER_VALVE,
  ACCESSORY_CATEGORY_DIMMER,
  ACCESSORY_CATEGORY_TUNABLE_WHITE,
  ACCESSORY_CATEGORY_SHUTTER,
  ACCESSORY_CATEGORY_PUNCH_BUTTON,
  ACCESSORY_CATEGORY_BINARY_BUTTON,
  ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT,
  ACCESSORY_CATEGORY_STATUS_DISPLAY,
  ACCESSORY_CATEGORY_RGBW,
  ACCESSORY_CATEGORY_AUDIO,
} from "../../utils/accessory-utils";

export default {
  name: "NewAccessory",
  components: {
    "app-navigation": Navigation,
    StepOne,
    StepTwo,
    LogoSwitchInputs,
    LogoSensorInputs,
    LogoShutterInputs,
    KnxSwitchInputs,
    KnxDimmerInputs,
    KnxTunableWhiteInputs,
    KnxShutterInputs,
    KnxThermostatInputs,
    KnxPunchInputs,
    KnxBinaryInputs,
    GpioSensorInputs,
    GpioSwitchInputs,
    KnxAdvThermostatInputs,
    KnxSensorInputs,
    KnxStatusDisplayInputs,
    ModbusSwitchInputs,
    ModbusSensorInputs,
    ModbusAdvancedThermostatInputs,
    CoolmasterThermostatInputs,
    KnxRGBWDimmerInputs,
    KnxAudioInputs,
  },
  data: function() {
    return {
      accessoryData: {
        roomId: DEFAULT_ROOM_ID,
        icon: "ic_switch_onoff",
        favorite: false,
        active: true,
        details: {},
      },

      categoryNames,
      loading: false,
      activeStep: 0,
      steps: [StepOne, StepTwo],
    };
  },

  watch: {
    "accessoryData.driverId"(value) {
      console.log("watch", value);
      this.accessoryData = {
        id: this.id,
        name: this.accessoryData.name,
        roomId: this.accessoryData.roomId,
        icon: this.accessoryData.icon,
        favorite: this.accessoryData.favorite,
        active: this.accessoryData.active,
        driverId: value,
        details: {},
      };
    },

    "accessoryData.category"(value) {
      console.log("watch", value);
      this.accessoryData = {
        id: this.id,
        name: this.accessoryData.name,
        roomId: this.accessoryData.roomId,
        driverId: this.accessoryData.driverId,
        icon: this.accessoryData.icon,
        favorite: this.accessoryData.favorite,
        active: this.accessoryData.active,
        displayCategory: this.accessoryData.displayCategory,
        category: value,
        details: {},
      };
    },
  },

  methods: {
    className,
    group,

    driverName(driverId) {
      return driverTitle(
        this.$store.getters.drivers.find((driver) => driver.id == driverId)
          .driverType
      );
    },

    nextStep(e) {
      this.$refs.accessoryForm.validate((valid) => {
        if (valid) {
          if (this.activeStep < 2) {
            if (this.activeStep == 1) {
              let lastStep = null;

              const driverType = this.$store.getters.drivers.find(
                (driver) => driver.id == this.accessoryData.driverId
              ).driverType;
              console.log("driverType : " + driverType);
              switch (driverType) {
                case DRIVER_TYPE_KNXIP:
                case DRIVER_TYPE_KNXTP:
                  if (
                    [
                      ACCESSORY_CATEGORY_SWITCH,
                      ACCESSORY_CATEGORY_SIREN
                    ].includes(this.accessoryData.category)
                  )
                    lastStep = KnxSwitchInputs;
                  else if (
                    this.accessoryData.category == ACCESSORY_CATEGORY_DIMMER
                  )
                    lastStep = KnxDimmerInputs;
                  else if (
                    this.accessoryData.category == ACCESSORY_CATEGORY_TUNABLE_WHITE
                  )
                    lastStep = KnxTunableWhiteInputs;
                  else if (
                    this.accessoryData.category == ACCESSORY_CATEGORY_RGBW
                  )
                    lastStep = KnxRGBWDimmerInputs;
                  else if (
                    this.accessoryData.category == ACCESSORY_CATEGORY_AUDIO
                  )
                    lastStep = KnxAudioInputs;
                  else if (
                    this.accessoryData.category == ACCESSORY_CATEGORY_SHUTTER
                  )
                    lastStep = KnxShutterInputs;
                  else if (
                    this.accessoryData.category ==
                    ACCESSORY_CATEGORY_PUNCH_BUTTON
                  )
                    lastStep = KnxPunchInputs;
                  else if (
                    this.accessoryData.category ==
                    ACCESSORY_CATEGORY_BINARY_BUTTON
                  )
                    lastStep = KnxBinaryInputs;
                  else if (
                    this.accessoryData.category ==
                    ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT
                  )
                    lastStep = KnxAdvThermostatInputs;
                  else if (
                    this.accessoryData.category ==
                    ACCESSORY_CATEGORY_STATUS_DISPLAY
                  )
                    lastStep = KnxStatusDisplayInputs;
                  else if (
                    [
                      ACCESSORY_CATEGORY_SENSOR_WINDOW,
                      ACCESSORY_CATEGORY_SENSOR_DOOR,
                      ACCESSORY_CATEGORY_SENSOR_GAS,
                      ACCESSORY_CATEGORY_SENSOR_LEAK,
                      ACCESSORY_CATEGORY_SENSOR_MOTION,
                      ACCESSORY_CATEGORY_SENSOR_SMOKE,
                    ].includes(this.accessoryData.category)
                  )
                    lastStep = KnxSensorInputs;
                  else lastStep = KnxThermostatInputs;
                  break;

                case DRIVER_TYPE_LOGO:
                  if (
                    [
                      ACCESSORY_CATEGORY_SWITCH,
                      ACCESSORY_CATEGORY_CONTACTOR,
                      ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
                      ACCESSORY_CATEGORY_ELEVATOR,
                      ACCESSORY_CATEGORY_GAS_VALVE,
                      ACCESSORY_CATEGORY_SIREN,
                      ACCESSORY_CATEGORY_WATER_VALVE,
                    ].includes(this.accessoryData.category)
                  )
                    lastStep = LogoSwitchInputs;
                  else if (
                    [
                      ACCESSORY_CATEGORY_SENSOR_WINDOW,
                      ACCESSORY_CATEGORY_SENSOR_DOOR,
                      ACCESSORY_CATEGORY_SENSOR_GAS,
                      ACCESSORY_CATEGORY_SENSOR_LEAK,
                      ACCESSORY_CATEGORY_SENSOR_MOTION,
                      ACCESSORY_CATEGORY_SENSOR_SMOKE,
                    ].includes(this.accessoryData.category)
                  )
                    lastStep = LogoSensorInputs;
                  else lastStep = LogoShutterInputs;
                  break;
                case DRIVER_TYPE_GPIO:
                  if (
                    [
                      ACCESSORY_CATEGORY_SWITCH,
                      ACCESSORY_CATEGORY_CONTACTOR,
                      ACCESSORY_CATEGORY_ELECTRICAL_DEVICE,
                      ACCESSORY_CATEGORY_ELEVATOR,
                      ACCESSORY_CATEGORY_GAS_VALVE,
                      ACCESSORY_CATEGORY_SIREN,
                      ACCESSORY_CATEGORY_WATER_VALVE,
                    ].includes(this.accessoryData.category)
                  )
                    lastStep = GpioSwitchInputs;
                  else if (
                    [
                      ACCESSORY_CATEGORY_SENSOR_WINDOW,
                      ACCESSORY_CATEGORY_SENSOR_DOOR,
                      ACCESSORY_CATEGORY_SENSOR_GAS,
                      ACCESSORY_CATEGORY_SENSOR_LEAK,
                      ACCESSORY_CATEGORY_SENSOR_MOTION,
                      ACCESSORY_CATEGORY_SENSOR_SMOKE,
                    ].includes(this.accessoryData.category)
                  )
                    lastStep = GpioSensorInputs;
                  break;
                case DRIVER_TYPE_MODBUS:
                  if (this.accessoryData.category == ACCESSORY_CATEGORY_SWITCH)
                    lastStep = ModbusSwitchInputs;
                  else if (
                    [
                      ACCESSORY_CATEGORY_SENSOR_MOTION,
                      ACCESSORY_CATEGORY_SENSOR_DOOR,
                      ACCESSORY_CATEGORY_SENSOR_WINDOW,
                      ACCESSORY_CATEGORY_SENSOR_LEAK,
                      ACCESSORY_CATEGORY_SENSOR_GAS,
                      ACCESSORY_CATEGORY_SENSOR_SMOKE,
                    ].includes(this.accessoryData.category)
                  )
                    lastStep = ModbusSensorInputs;
                  else if (
                    this.accessoryData.category ==
                    ACCESSORY_CATEGORY_ADVANCED_THERMOSTAT
                  ) {
                    lastStep = ModbusAdvancedThermostatInputs;
                  }
                  break;
                case DRIVER_TYPE_COOLMASTER:
                  lastStep = CoolmasterThermostatInputs;
                  break;
              }

              if (this.steps[2] != lastStep) {
                this.steps[2] = lastStep;
                if (
                  [
                    KnxDimmerInputs,
                    KnxSwitchInputs,
                    KnxShutterInputs,
                    KnxThermostatInputs,
                  ].includes(lastStep)
                ) {
                  delete this.accessoryData.details.knxAddresses;
                }
              }
            }

            this.activeStep++;
          } else {
            const driverType = this.$store.getters.drivers.find(
              (driver) => driver.id == this.accessoryData.driverId
            ).driverType;
            if (
              driverType == DRIVER_TYPE_KNXTP ||
              driverType == DRIVER_TYPE_KNXIP
            ) {
              console.log('REFS',this.$refs.comp)
              if (!this.$refs.comp.validate()) {
                return;
              }
            }

            this.loading = true;

            this.accessoryData.group = this.group(this.accessoryData.category);

            if (this.accessoryData.details.hasOwnProperty("knxAddresses")) {
              this.accessoryData.details.knxAddresses = this.accessoryData.details.knxAddresses.map(
                (addr) => {
                  if (addr.readAddress)
                    addr.readAddress = addr.readAddress.replace(/_/g, "");
                  if (addr.writeAddress)
                    addr.writeAddress = addr.writeAddress.replace(/_/g, "");
                  return addr;
                }
              );
            }

            const data = {
              type: this.className(this.accessoryData.category),
              properties: this.accessoryData,
            };
            this.$store
              .dispatch("addAccessory", data)
              .then((response) => {
                this.loading = false;
                this.$message({
                  type: "success",
                  message: this.$t(response.message),
                });

                this.$router.push({ name: "Accessories" });
              })
              .catch((error) => {
                this.loading = false;
                this.$message({
                  type: "error",
                  message: this.$t(error),
                });
              });
          }
        }
      });
    },

    prevStep(e) {
      if (this.activeStep > 0) this.activeStep--;
    },
  },

  created() {
    this.accessoryData.roomId = this.$route.params.roomId
      ? this.$route.params.roomId
      : DEFAULT_ROOM_ID;
  },
};
</script>

<style scoped>
.form-container {
  margin-right: 20px;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding: 25px 25px 25px 25px;
  border: 1px solid #eaeaea;
}
</style>
