var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("accessory.accessory-add"),
          backButtonText: _vm.$t("navigation.back")
        }
      }),
      _c(
        "el-steps",
        {
          staticStyle: { margin: "20px" },
          attrs: { active: _vm.activeStep, "finish-status": "success" }
        },
        [
          _c("el-step", {
            attrs: {
              title: _vm.$t("accessory.step-one"),
              description: _vm.$t("accessory.step-one-desc")
            }
          }),
          _c("el-step", {
            attrs: {
              title: _vm.$t("accessory.step-two"),
              description: _vm.$t("accessory.step-two-desc")
            }
          }),
          _c("el-step", {
            attrs: {
              title: _vm.$t("accessory.step-three"),
              description: _vm.$t("accessory.step-three-desc")
            }
          })
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-left": "20px", "margin-right": "20px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-d-arrow-left" },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.prevStep($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accessory.back")))]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "text", loading: _vm.loading },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.nextStep($event)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.activeStep == 2
                        ? _vm.$t("accessory.save")
                        : _vm.$t("accessory.next")
                    ) + "\n        "
                  ),
                  _c("i", {
                    class: {
                      "el-icon-d-arrow-right": _vm.activeStep < 2,
                      "el-icon-download": _vm.activeStep == 2
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "accessoryForm",
          staticClass: "form-container",
          attrs: {
            model: _vm.accessoryData,
            "label-position": "left",
            "label-width": "220px",
            "status-icon": ""
          }
        },
        [
          _c(_vm.steps[_vm.activeStep], {
            ref: "comp",
            tag: "component",
            attrs: { accessoryData: _vm.accessoryData }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }