<template>
  <div>

    <el-form-item prop="name" :label="$t('accessory.accessory-name')" style="width: 400px" :rules="[{required: true, message: $t('accessory.form-rules.type-name')}]">
      <el-input type="text" v-model="accessoryData.name" auto-complete="on" :placeholder="$t('accessory.type-accessory-name')">
      </el-input>
    </el-form-item>


    <el-form-item v-if="false" :label="$t('accessory.icon')">

      <el-col :span="8">
        <el-select v-model="accessoryData.icon" filterable :placeholder="$t('accessory.select-icon')">
          <el-option v-for="(icon, index) in icons" :key="index" :label="icon.title" :value="icon.value">
            <span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">{{icon.title}}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="16">
              <span>
                <img @error="onImageError" :src="'/assets/icons/' + accessoryData.icon + '.png'" class="icon-contact">
              </span>
      </el-col>

    </el-form-item>


    <el-form-item :label="$t('accessory.room')">
      <el-select v-model="accessoryData.roomId" :placeholder="$t('accessory.select-room')" filterable>
        <el-option v-for="room in $store.getters.rooms" :label="room.name" :value="room.id" :key="room.id"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="driverId" :label="$t('accessory.driver')" :rules="[{required: true, message: $t('accessory.form-rules.select-driver')}]">
      <el-select v-model="accessoryData.driverId" :placeholder="$t('accessory.select-driver')">
        <el-option v-for="driver in drivers" :label="driverName(driver.id)" :value="driver.id" :key="driver.id">
          <span style="font-weight: bold">{{ driverName(driver.id) }}</span>
          <span v-if="driver.ipAddress"> - {{ driver.ipAddress }}</span>
          <span v-if="isModbusRTUDriver(driver)"> - {{ getModbusRTUDesc(driver) }}</span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item prop="favorite" :label="$t('accessory.favorite')" style="width: 400px">
      <el-switch v-model="favorite" ></el-switch>
    </el-form-item>

    <el-form-item prop="active" :label="$t('accessory.active')" style="width: 400px">
      <el-switch v-model="active" ></el-switch>
    </el-form-item>

  </div>

</template>

<script>

  import {driverTitle, DRIVER_TYPE_COREOS_PANEL, DRIVER_TYPE_CORECENTER,DRIVER_TYPE_MODBUS, CONNECTION_RTU, RS485} from "@/utils/driver-utils";
  import {accessoryMixin} from "./accessoryMixin";

  export default {
    name: "StepOne",

    mixins: [accessoryMixin],

    data: function(){
      return {
        favorite: 'favorite' in this.accessoryData ? this.accessoryData.favorite : false,
        active: 'active' in this.accessoryData ? this.accessoryData.active : true,
      }
    },

    computed: {
      drivers(){
        return this.$store.getters.drivers.filter(driver => driver.driverType != DRIVER_TYPE_CORECENTER && driver.driverType != DRIVER_TYPE_COREOS_PANEL)
      }
    },

    watch: {
      favorite: function (value) {
        this.accessoryData.favorite = value
      },
      active: function (value) {
        this.accessoryData.active = value
      },
    },

    props: {
      accessoryData: {}
    },

    methods: {
      getModbusRTUDesc(driver){
        if(driver.driverType === DRIVER_TYPE_MODBUS){
          if(driver.extraData){
            try{
              let data = JSON.parse(driver.extraData);
              if(data.connectionType && data.connectionType == CONNECTION_RTU){
                  return data.connector === RS485 ? "RS485" : "RS232"
              }
            }
            catch(e){
                return "-"
            }
          }
        }
      },
      isModbusRTUDriver(driver){
        if(driver.driverType == DRIVER_TYPE_MODBUS){
          if(driver.extraData){
            try{
              let data = JSON.parse(driver.extraData);
              if(data.connectionType && data.connectionType == CONNECTION_RTU){
                  return true
              }
            }
            catch(e){
                return false
            }
          }          
        }
        return false
      },
      driverName(driverId) {
        console.log(driverId)
        return driverTitle(this.$store.getters.drivers.find(driver => driver.id == driverId).driverType)
      }
    },

  }
</script>

<style scoped>
  .icon-select-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-select {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }
</style>