<template>

  <div>
    <el-form-item prop="category" :label="$t('accessory.accessory-type')" :rules="[{required: true, message: $t('accessory.form-rules.select-category')}]">

      <el-select v-model="accessoryData.category" :placeholder="$t('accessory.select-accessory-type')" @change="onSelectCategory">
        <el-option v-for="category in categoryNames" :label="$t(category.title)" :value="category.value" :key="category.value"></el-option>
      </el-select>

    </el-form-item>

    <el-form-item v-show="showDisplayCategory" prop="displayCategory" :label="$t('accessory.display-category')" :rules="[{required: true, message: $t('accessory.form-rules.select-display-category')}]">

      <el-select v-model="accessoryData.displayCategory" :placeholder="$t('accessory.select-display-category')">
        <el-option v-for="category in displayCategories" :label="$t(category.title)" :value="category.value" :key="category.value"></el-option>
      </el-select>

    </el-form-item>


    <el-form-item v-if="accessoryData.category" :label="$t('accessory.icon')">

      <el-col :span="8">
        <el-select v-model="accessoryData.icon" filterable :placeholder="$t('accessory.select-icon')">
          <el-option v-for="(icon, index) in getIcons" :key="index" :label="icon.title" :value="icon.value">
            <span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">{{icon.title}}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="16">
              <span>
                <img @error="onImageError" :src="'/assets/icons/' + accessoryData.icon + '.png'" class="icon-contact">
              </span>
      </el-col>

    </el-form-item>

  </div>

</template>

<script>
import { modbusCategories } from "@/utils/modbus";
import { logoCategories } from "@/utils/logo";
import { knxCategories } from "@/utils/knx";
import { coolmasterCategories } from "@/utils/coolmaster";
import { gpioCategories, gpioInputCategories } from "@/utils/gpio";
import { accessoryMixin } from "./accessoryMixin";
import { group,   ACCESSORY_CATEGORY_SIREN } from "@/utils/accessory-utils";
import {
  DRIVER_TYPE_MODBUS,
  DRIVER_TYPE_KNXTP,
  DRIVER_TYPE_KNXIP,
  DRIVER_TYPE_LOGO,
  DRIVER_TYPE_GPIO,
  DRIVER_TYPE_COOLMASTER,
} from "../../../utils/driver-utils";

export default {
  name: "StepTwo",
  props: {
    accessoryData: {},
  },

  mixins: [accessoryMixin],

  computed: {
    categoryNames() {
      switch (this.getDriverType()) {
        case DRIVER_TYPE_LOGO:
          return logoCategories();
        case DRIVER_TYPE_KNXIP:
        case DRIVER_TYPE_KNXTP:
          return knxCategories();
        case DRIVER_TYPE_GPIO:
          if (this.$store.getters.relayType == 0) return gpioInputCategories();
          else return gpioCategories();
        case DRIVER_TYPE_MODBUS:
          return modbusCategories();
        case DRIVER_TYPE_COOLMASTER:
          return coolmasterCategories();
      }
    },

    showDisplayCategory() {
      if (!this.accessoryData.category) return false;
      return (
        this.accessoryData.category == 1 ||
        this.accessoryData.category == 27 ||
        this.accessoryData.category == 28 ||
        this.accessoryData.category == 52
      );
    },

    getIcons() {
      const category = this.accessoryData.category;
      if (category) {
        const group = this.group(category);

        const myIcons = this.icons.filter((icon) => icon.type == group);
        const otherIcons = this.icons.filter((icon) => icon.type != group);

        return [...myIcons, ...otherIcons];
      }
      return [];
    },
  },

  data: function() {
    return {};
  },

  methods: {
    group,
    logoCategories,
    knxCategories,
    gpioCategories,
    modbusCategories,
    coolmasterCategories,
    getDriverType() {
      return this.$store.getters.drivers.find(
        (driver) => driver.id == this.accessoryData.driverId
      ).driverType;
    },

    onSelectCategory(category) {
      console.log("Category : " + category);

      var dispCategory = this.displayCategory(category);
      console.log("Disp Category : " + dispCategory);
      if (dispCategory >= 0) this.accessoryData.displayCategory = dispCategory;

      if(category == ACCESSORY_CATEGORY_SIREN) this.accessoryData.active = false //for siren accessory

      this.accessoryData.icon = this.getIcons[0].value;
    },
  },
};
</script>

<style scoped>
.icon-select-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-select {
  filter: brightness(0.45);
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.icon-contact {
  filter: brightness(0.45);
  width: 40px;
  height: 40px;
}
</style>
