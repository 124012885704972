var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          staticStyle: { width: "400px" },
          attrs: {
            prop: "name",
            label: _vm.$t("accessory.accessory-name"),
            rules: [
              {
                required: true,
                message: _vm.$t("accessory.form-rules.type-name")
              }
            ]
          }
        },
        [
          _c("el-input", {
            attrs: {
              type: "text",
              "auto-complete": "on",
              placeholder: _vm.$t("accessory.type-accessory-name")
            },
            model: {
              value: _vm.accessoryData.name,
              callback: function($$v) {
                _vm.$set(_vm.accessoryData, "name", $$v)
              },
              expression: "accessoryData.name"
            }
          })
        ],
        1
      ),
      false
        ? _c(
            "el-form-item",
            { attrs: { label: _vm.$t("accessory.icon") } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("accessory.select-icon")
                      },
                      model: {
                        value: _vm.accessoryData.icon,
                        callback: function($$v) {
                          _vm.$set(_vm.accessoryData, "icon", $$v)
                        },
                        expression: "accessoryData.icon"
                      }
                    },
                    _vm._l(_vm.icons, function(icon, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: icon.title, value: icon.value }
                        },
                        [
                          _c("span", { staticClass: "icon-select-layout" }, [
                            _c("img", {
                              staticClass: "icon-select",
                              attrs: {
                                src: "/assets/icons/" + icon.value + ".png"
                              },
                              on: { error: _vm.onImageError }
                            }),
                            _vm._v(_vm._s(icon.title))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("span", [
                  _c("img", {
                    staticClass: "icon-contact",
                    attrs: {
                      src: "/assets/icons/" + _vm.accessoryData.icon + ".png"
                    },
                    on: { error: _vm.onImageError }
                  })
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("accessory.room") } },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("accessory.select-room"),
                filterable: ""
              },
              model: {
                value: _vm.accessoryData.roomId,
                callback: function($$v) {
                  _vm.$set(_vm.accessoryData, "roomId", $$v)
                },
                expression: "accessoryData.roomId"
              }
            },
            _vm._l(_vm.$store.getters.rooms, function(room) {
              return _c("el-option", {
                key: room.id,
                attrs: { label: room.name, value: room.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "driverId",
            label: _vm.$t("accessory.driver"),
            rules: [
              {
                required: true,
                message: _vm.$t("accessory.form-rules.select-driver")
              }
            ]
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: _vm.$t("accessory.select-driver") },
              model: {
                value: _vm.accessoryData.driverId,
                callback: function($$v) {
                  _vm.$set(_vm.accessoryData, "driverId", $$v)
                },
                expression: "accessoryData.driverId"
              }
            },
            _vm._l(_vm.drivers, function(driver) {
              return _c(
                "el-option",
                {
                  key: driver.id,
                  attrs: { label: _vm.driverName(driver.id), value: driver.id }
                },
                [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s(_vm.driverName(driver.id)))
                  ]),
                  driver.ipAddress
                    ? _c("span", [_vm._v(" - " + _vm._s(driver.ipAddress))])
                    : _vm._e(),
                  _vm.isModbusRTUDriver(driver)
                    ? _c("span", [
                        _vm._v(" - " + _vm._s(_vm.getModbusRTUDesc(driver)))
                      ])
                    : _vm._e()
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "400px" },
          attrs: { prop: "favorite", label: _vm.$t("accessory.favorite") }
        },
        [
          _c("el-switch", {
            model: {
              value: _vm.favorite,
              callback: function($$v) {
                _vm.favorite = $$v
              },
              expression: "favorite"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "400px" },
          attrs: { prop: "active", label: _vm.$t("accessory.active") }
        },
        [
          _c("el-switch", {
            model: {
              value: _vm.active,
              callback: function($$v) {
                _vm.active = $$v
              },
              expression: "active"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }