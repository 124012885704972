var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "category",
            label: _vm.$t("accessory.accessory-type"),
            rules: [
              {
                required: true,
                message: _vm.$t("accessory.form-rules.select-category")
              }
            ]
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: _vm.$t("accessory.select-accessory-type") },
              on: { change: _vm.onSelectCategory },
              model: {
                value: _vm.accessoryData.category,
                callback: function($$v) {
                  _vm.$set(_vm.accessoryData, "category", $$v)
                },
                expression: "accessoryData.category"
              }
            },
            _vm._l(_vm.categoryNames, function(category) {
              return _c("el-option", {
                key: category.value,
                attrs: { label: _vm.$t(category.title), value: category.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDisplayCategory,
              expression: "showDisplayCategory"
            }
          ],
          attrs: {
            prop: "displayCategory",
            label: _vm.$t("accessory.display-category"),
            rules: [
              {
                required: true,
                message: _vm.$t("accessory.form-rules.select-display-category")
              }
            ]
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("accessory.select-display-category")
              },
              model: {
                value: _vm.accessoryData.displayCategory,
                callback: function($$v) {
                  _vm.$set(_vm.accessoryData, "displayCategory", $$v)
                },
                expression: "accessoryData.displayCategory"
              }
            },
            _vm._l(_vm.displayCategories, function(category) {
              return _c("el-option", {
                key: category.value,
                attrs: { label: _vm.$t(category.title), value: category.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm.accessoryData.category
        ? _c(
            "el-form-item",
            { attrs: { label: _vm.$t("accessory.icon") } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("accessory.select-icon")
                      },
                      model: {
                        value: _vm.accessoryData.icon,
                        callback: function($$v) {
                          _vm.$set(_vm.accessoryData, "icon", $$v)
                        },
                        expression: "accessoryData.icon"
                      }
                    },
                    _vm._l(_vm.getIcons, function(icon, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: icon.title, value: icon.value }
                        },
                        [
                          _c("span", { staticClass: "icon-select-layout" }, [
                            _c("img", {
                              staticClass: "icon-select",
                              attrs: {
                                src: "/assets/icons/" + icon.value + ".png"
                              },
                              on: { error: _vm.onImageError }
                            }),
                            _vm._v(_vm._s(icon.title))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("span", [
                  _c("img", {
                    staticClass: "icon-contact",
                    attrs: {
                      src: "/assets/icons/" + _vm.accessoryData.icon + ".png"
                    },
                    on: { error: _vm.onImageError }
                  })
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }